import { default as indextyzGoLafvvMeta } from "/Users/igor/Sites/platform/apps/console/web/pages/[project]/index.vue?macro=true";
import { default as catalog_45dataou89wRlKckMeta } from "/Users/igor/Sites/platform/apps/console/web/pages/[project]/ml/catalog-data.vue?macro=true";
import { default as customersQCCeUDbP7IMeta } from "/Users/igor/Sites/platform/apps/console/web/pages/[project]/ml/customers.vue?macro=true";
import { default as eventsJHdxT4f3zKMeta } from "/Users/igor/Sites/platform/apps/console/web/pages/[project]/ml/events.vue?macro=true";
import { default as indexD85juAvAuAMeta } from "/Users/igor/Sites/platform/apps/console/web/pages/[project]/ml/index.vue?macro=true";
import { default as integrationsXSoBKmINkwMeta } from "/Users/igor/Sites/platform/apps/console/web/pages/[project]/ml/integrations.vue?macro=true";
import { default as editTSMKzp26e0Meta } from "/Users/igor/Sites/platform/apps/console/web/pages/[project]/ml/models/[model_id]/edit.vue?macro=true";
import { default as indexEIBvQLn0TKMeta } from "/Users/igor/Sites/platform/apps/console/web/pages/[project]/ml/models/[model_id]/index.vue?macro=true";
import { default as indexKUeTgDbJsSMeta } from "/Users/igor/Sites/platform/apps/console/web/pages/[project]/ml/models/index.vue?macro=true";
import { default as web_45tagsu6WSniExDtMeta } from "/Users/igor/Sites/platform/apps/console/web/pages/[project]/ml/web-tags.vue?macro=true";
import { default as indexHEQw3sE8geMeta } from "/Users/igor/Sites/platform/apps/console/web/pages/abandoned-cart/index.vue?macro=true";
import { default as _2faa3k4L6lPWOMeta } from "/Users/igor/Sites/platform/apps/console/web/pages/account/2fa.vue?macro=true";
import { default as indexe6ebIAPGlOMeta } from "/Users/igor/Sites/platform/apps/console/web/pages/account/index.vue?macro=true";
import { default as _91reset_password_code_93xedZqEyxcOMeta } from "/Users/igor/Sites/platform/apps/console/web/pages/account/reset-password/[reset_password_code].vue?macro=true";
import { default as setuphQC2ZW5uRjMeta } from "/Users/igor/Sites/platform/apps/console/web/pages/account/setup.vue?macro=true";
import { default as indexCgOsIPEsgmMeta } from "/Users/igor/Sites/platform/apps/console/web/pages/index.vue?macro=true";
import { default as indexiCzdgyvJ1yMeta } from "/Users/igor/Sites/platform/apps/console/web/pages/login/index.vue?macro=true";
import { default as indexy8iCKb0HixMeta } from "/Users/igor/Sites/platform/apps/console/web/pages/org-[org_id]/billing/index.vue?macro=true";
import { default as indexe4gQFuups9Meta } from "/Users/igor/Sites/platform/apps/console/web/pages/register/index.vue?macro=true";
import { default as indexowMoRi0FpHMeta } from "/Users/igor/Sites/platform/apps/console/web/pages/support/index.vue?macro=true";
export default [
  {
    name: "project",
    path: "/:project()",
    component: () => import("/Users/igor/Sites/platform/apps/console/web/pages/[project]/index.vue").then(m => m.default || m)
  },
  {
    name: "project-ml-catalog-data",
    path: "/:project()/ml/catalog-data",
    component: () => import("/Users/igor/Sites/platform/apps/console/web/pages/[project]/ml/catalog-data.vue").then(m => m.default || m)
  },
  {
    name: "project-ml-customers",
    path: "/:project()/ml/customers",
    component: () => import("/Users/igor/Sites/platform/apps/console/web/pages/[project]/ml/customers.vue").then(m => m.default || m)
  },
  {
    name: "project-ml-events",
    path: "/:project()/ml/events",
    component: () => import("/Users/igor/Sites/platform/apps/console/web/pages/[project]/ml/events.vue").then(m => m.default || m)
  },
  {
    name: "project-ml",
    path: "/:project()/ml",
    component: () => import("/Users/igor/Sites/platform/apps/console/web/pages/[project]/ml/index.vue").then(m => m.default || m)
  },
  {
    name: "project-ml-integrations",
    path: "/:project()/ml/integrations",
    component: () => import("/Users/igor/Sites/platform/apps/console/web/pages/[project]/ml/integrations.vue").then(m => m.default || m)
  },
  {
    name: "project-ml-models-model_id-edit",
    path: "/:project()/ml/models/:model_id()/edit",
    component: () => import("/Users/igor/Sites/platform/apps/console/web/pages/[project]/ml/models/[model_id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "project-ml-models-model_id",
    path: "/:project()/ml/models/:model_id()",
    component: () => import("/Users/igor/Sites/platform/apps/console/web/pages/[project]/ml/models/[model_id]/index.vue").then(m => m.default || m)
  },
  {
    name: "project-ml-models",
    path: "/:project()/ml/models",
    component: () => import("/Users/igor/Sites/platform/apps/console/web/pages/[project]/ml/models/index.vue").then(m => m.default || m)
  },
  {
    name: "project-ml-web-tags",
    path: "/:project()/ml/web-tags",
    component: () => import("/Users/igor/Sites/platform/apps/console/web/pages/[project]/ml/web-tags.vue").then(m => m.default || m)
  },
  {
    name: "abandoned-cart",
    path: "/abandoned-cart",
    component: () => import("/Users/igor/Sites/platform/apps/console/web/pages/abandoned-cart/index.vue").then(m => m.default || m)
  },
  {
    name: "account-2fa",
    path: "/account/2fa",
    component: () => import("/Users/igor/Sites/platform/apps/console/web/pages/account/2fa.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "/account",
    component: () => import("/Users/igor/Sites/platform/apps/console/web/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-reset-password-reset_password_code",
    path: "/account/reset-password/:reset_password_code()",
    component: () => import("/Users/igor/Sites/platform/apps/console/web/pages/account/reset-password/[reset_password_code].vue").then(m => m.default || m)
  },
  {
    name: "account-setup",
    path: "/account/setup",
    component: () => import("/Users/igor/Sites/platform/apps/console/web/pages/account/setup.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/Users/igor/Sites/platform/apps/console/web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/Users/igor/Sites/platform/apps/console/web/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "org-org_id-billing",
    path: "/org-:org_id()/billing",
    component: () => import("/Users/igor/Sites/platform/apps/console/web/pages/org-[org_id]/billing/index.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    component: () => import("/Users/igor/Sites/platform/apps/console/web/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: "support",
    path: "/support",
    component: () => import("/Users/igor/Sites/platform/apps/console/web/pages/support/index.vue").then(m => m.default || m)
  }
]