import revive_payload_client_u3mNhLqlLX from "/Users/igor/Sites/platform/apps/console/web/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.21_2o2cec4mgmkalstg3nscpzkfeu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_CCM2xgrx6o from "/Users/igor/Sites/platform/apps/console/web/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.21_2o2cec4mgmkalstg3nscpzkfeu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_MEKKBK7djK from "/Users/igor/Sites/platform/apps/console/web/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.21_2o2cec4mgmkalstg3nscpzkfeu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_5FDlINIDB2 from "/Users/igor/Sites/platform/apps/console/web/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.21_2o2cec4mgmkalstg3nscpzkfeu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_XBTbN7twyL from "/Users/igor/Sites/platform/apps/console/web/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.21_2o2cec4mgmkalstg3nscpzkfeu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_nSv3x4yiVu from "/Users/igor/Sites/platform/apps/console/web/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.21_2o2cec4mgmkalstg3nscpzkfeu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_vZ37vcc74P from "/Users/igor/Sites/platform/apps/console/web/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.21_2o2cec4mgmkalstg3nscpzkfeu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_E6NzYBN4ps from "/Users/igor/Sites/platform/apps/console/web/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.4_rollup@4.21.0_vue@3.4.38/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/Users/igor/Sites/platform/apps/console/web/.nuxt/components.plugin.mjs";
import prefetch_client_iMcjcr5lG7 from "/Users/igor/Sites/platform/apps/console/web/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.21_2o2cec4mgmkalstg3nscpzkfeu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_maO3nvoKug from "/Users/igor/Sites/platform/apps/console/web/node_modules/.pnpm/@nuxtjs+color-mode@3.4.4_magicast@0.3.4_rollup@4.21.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_XTfZqKz2qg from "/Users/igor/Sites/platform/apps/console/web/node_modules/.pnpm/nuxt-csurf@1.6.2_magicast@0.3.4_rollup@4.21.0/node_modules/nuxt-csurf/dist/runtime/plugin.js";
import fontawesome_cn2c4tOOHz from "/Users/igor/Sites/platform/apps/console/web/plugins/fontawesome.ts";
import posthog_client_fUucxKWhZE from "/Users/igor/Sites/platform/apps/console/web/plugins/posthog.client.ts";
import sentry_3AyO8nEfhE from "/Users/igor/Sites/platform/apps/console/web/plugins/sentry.ts";
export default [
  revive_payload_client_u3mNhLqlLX,
  unhead_CCM2xgrx6o,
  router_MEKKBK7djK,
  payload_client_5FDlINIDB2,
  navigation_repaint_client_XBTbN7twyL,
  check_outdated_build_client_nSv3x4yiVu,
  chunk_reload_client_vZ37vcc74P,
  plugin_vue3_E6NzYBN4ps,
  components_plugin_KR1HBZs4kY,
  prefetch_client_iMcjcr5lG7,
  plugin_client_maO3nvoKug,
  plugin_XTfZqKz2qg,
  fontawesome_cn2c4tOOHz,
  posthog_client_fUucxKWhZE,
  sentry_3AyO8nEfhE
]