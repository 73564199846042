import * as Sentry from '@sentry/vue'

// async function lazyLoadSentryIntegrations()
// {
// 	// don't load on server
// 	if (!import.meta.client)
// 		return

// 	const { Replay } = await import('@sentry/vue')
// 	Sentry.addIntegration(new Replay({
// 		maskAllText: false,
// 		blockAllMedia: false,
// 	}))
// }

// function getSentryIntegrations()
// {
// 	// don't load on server
// 	if (!import.meta.client)
// 		return []

// 	const router = useRouter()
// 	const browserTracing = new Sentry.BrowserClient({ 
// 		routingInstrumentation: Sentry.vueIntegration,
// 	})

// 	return [ browserTracing ]
// }

export default defineNuxtPlugin({
	name: 'sentry',
	parallel: true,
	async setup(nuxtApp)
	{
		// Disable in development mode
		if (import.meta.env.MODE === 'development')
			return
		
		const vueApp = nuxtApp.vueApp
		const config = useRuntimeConfig()
		
		if (!config.public.sentryDsnPublic)
		{
			console.error('No Sentry DSN, skipping Sentry initialization', config.public)
			return
		}
		
		Sentry.init({
			app: vueApp,
			dsn: config.public.sentryDsnPublic,
			// integrations: getSentryIntegrations(),
			
			sampleRate: 1,
			environment: import.meta.env.MODE,

			// Set tracesSampleRate to 1.0 to capture 100%
			// of transactions for performance monitoring.
			// We recommend adjusting this value in production
			tracesSampleRate: parseFloat(config.public.sentryTracesSampleRate ?? 0),

			// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
			// tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

			// This sets the sample rate. You may want this to be 100% while
			// in development and sample at a lower rate in production
			replaysSessionSampleRate: parseFloat(config.public.sentryReplaySampleRate ?? 0),

			// If the entire session is not sampled, use the below sample rate to sample
			// sessions when an error occurs.
			replaysOnErrorSampleRate: parseFloat(config.public.sentryErrorReplaySampleRate ?? 0),
		})

		// Lazy-load the replay integration to reduce bundle size
		// lazyLoadSentryIntegrations()
		
		console.log('Sentry init complete')
	}
})