import validate from "/Users/igor/Sites/platform/apps/console/web/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.21_2o2cec4mgmkalstg3nscpzkfeu/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/Users/igor/Sites/platform/apps/console/web/middleware/auth.global.ts";
import org_45global from "/Users/igor/Sites/platform/apps/console/web/middleware/org.global.ts";
import project_45global from "/Users/igor/Sites/platform/apps/console/web/middleware/project.global.ts";
import manifest_45route_45rule from "/Users/igor/Sites/platform/apps/console/web/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.21_2o2cec4mgmkalstg3nscpzkfeu/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  org_45global,
  project_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}