// const route = useRoute()

export const useConsoleStore = defineStore('console',
{
	state: () => ({
		// user: undefined,
		project: {
			name: '',
			internal_name: '',
			organization: '',
			organization_subscription_plans: {} as any,
		},
		
		organization: {
			_id: '',
			name: '',
			billing_settings: {
				payment_methods: [] as any[],
				subscriptions: [] as any[],
				metered_payment_threshold: 500,
			},
		},
	}),

	// actions: {

	// },
})