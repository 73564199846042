import { library, config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
	faUser, faCube, faBook, faCircleQuestion, faChartScatterBubble,
	faFileLines, faCode, faTableList, faUsers, faNetworkWired, faChartSimple,
	faDownload, faXmark, faSunBright, faMoon, faTrash, faSquarePlus,
	faChevronRight, faChevronLeft, 
} from '@fortawesome/pro-regular-svg-icons'

import {
	faChevronDown, faChevronLeft as fasChevronLeft, faChevronRight as fasChevronRight, faGear, faChartSimple as fasChartSimple,
	faArrowLeft as fasArrowLeft, faTrash as fasTrash, faBars as fasBars, faPlay as fasPlay,
	faArrowRightToArc as fasArrowRightToArc
} from '@fortawesome/pro-solid-svg-icons'

import { faGithub } from '@fortawesome/free-brands-svg-icons'

library.add(
	faChevronDown,
	faUser,
	faCube,
	faBook,
	faCircleQuestion,
	faChartScatterBubble,
	faFileLines,
	faCode,
	faTableList,
	faUsers,
	faNetworkWired,
	faChartSimple,
	faDownload,
	faXmark,
	faGithub,
	faSunBright,
	faMoon,
	faGear,
	faChevronLeft,
	fasChevronLeft,
	faChevronRight,
	fasChevronRight,
	fasChartSimple,
	fasArrowLeft,
	faTrash,
	fasTrash,
	faSquarePlus,
	fasBars,
	fasPlay,
	fasArrowRightToArc,
)

// This is important, we are going to let Nuxt worry about the CSS
config.autoAddCss = false

export default defineNuxtPlugin((nuxtApp) => {
	nuxtApp.vueApp.component('font-awesome-icon', FontAwesomeIcon, {})
})