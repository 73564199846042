export default defineNuxtRouteMiddleware(async (to, from) =>
{
	// const store = useConsoleStore()
	
	const user = useUser()
	const { data } = await useCsrfFetch('/api/auth/user')
	if (data.value.user)
	{
		user.value = data.value.user
		// store.user = data.user
	}
	else if (
			to.path != '/login/'
		&&	to.path != '/register/'
		&&	!to.path.startsWith('/account/reset-password')
		&&	!to.path.startsWith('/services-api/')
	)
	{
		return navigateTo('/login/')
	}
})