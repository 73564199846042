import type { User } from 'lucia'

type UserWithData = User & {
	full_name: string
	email: string
}

export const useUser = () => {
	const user = useState<UserWithData | null>('user', () => null)
	return user
}