export default defineNuxtRouteMiddleware(async (to, from) =>
{
	const url = to.params.project
	if (!url)
		return
	
	const { data } = await useCsrfFetch('/api/projects/' + url)
	if (!data || !data.value)
		return
	
	const store = useConsoleStore()
	store.project = data.value
})